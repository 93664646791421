define("discourse/plugins/discourse-chat-integration/admin/components/channel-param-row", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="input">
    <td class="label">
      <label for="param-{{@param.key}}">
        {{i18n
          (concat
            "chat_integration.provider."
            @channel.provider
            ".param."
            @param.key
            ".title"
          )
        }}
      </label>
    </td>
    <td>
      <input
        {{on "input" this.updateValue}}
        value={{get @channel.data @param.key}}
        type="text"
        name="param-{{@param.key}}"
      />
  
      <InputTip @validation={{this.validation}} />
    </td>
  </tr>
  
  <tr class="chat-instructions">
    <td></td>
    <td>
      <label>
        {{i18n
          (concat
            "chat_integration.provider."
            @channel.provider
            ".param."
            @param.key
            ".help"
          )
        }}
      </label>
    </td>
  </tr>
  */
  {
    "id": "dK7kW2IQ",
    "block": "[[[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[29,[\"param-\",[30,1,[\"key\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"chat_integration.provider.\",[30,2,[\"provider\"]],\".param.\",[30,1,[\"key\"]],\".title\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,2,[28,[37,2],[[30,2,[\"data\"]],[30,1,[\"key\"]]],null]],[16,3,[29,[\"param-\",[30,1,[\"key\"]]]]],[24,4,\"text\"],[4,[38,3],[\"input\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@validation\"],[[30,0,[\"validation\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"tr\"],[14,0,\"chat-instructions\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"chat_integration.provider.\",[30,2,[\"provider\"]],\".param.\",[30,1,[\"key\"]],\".help\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@param\",\"@channel\"],false,[\"i18n\",\"concat\",\"get\",\"on\",\"input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/channel-param-row.hbs",
    "isStrictMode": false
  });
  class ChannelParamRow extends _component2.default {
    get validation() {
      const value = this.args.channel.get(`data.${this.args.param.key}`);
      if (!value?.trim()) {
        return {
          failed: true
        };
      } else if (!this.args.param.regex) {
        return {
          ok: true
        };
      } else if (new RegExp(this.args.param.regex).test(value)) {
        return {
          ok: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.ok")
        };
      } else {
        return {
          failed: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.fail")
        };
      }
    }
    updateValue(event) {
      this.args.channel.set(`data.${this.args.param.key}`, event.target.value);
    }
    static #_ = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
  }
  _exports.default = ChannelParamRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChannelParamRow);
});