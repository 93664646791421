define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration-provider", ["exports", "@ember/object", "discourse/models/group", "discourse/routes/discourse"], function (_exports, _object, _group, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationProvider extends _discourse.default {
    async model(params) {
      const [channels, provider, groups] = await Promise.all([this.store.findAll("channel", {
        provider: params.provider
      }), this.modelFor("admin-plugins-chat-integration").findBy("id", params.provider), _group.default.findAll()]);
      channels.forEach(channel => {
        channel.set("rules", channel.rules.map(rule => {
          rule = this.store.createRecord("rule", rule);
          rule.set("channel", channel);
          return rule;
        }));
      });
      return {
        channels,
        provider,
        groups
      };
    }
    serialize(model) {
      return {
        provider: model.provider.id
      };
    }
    refreshProvider() {
      this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "refreshProvider", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationProvider;
});