define("discourse/plugins/discourse-chat-integration/admin/components/modal/edit-channel", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _object, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{on "submit" this.save}}
    @title={{i18n "chat_integration.edit_channel_modal.title"}}
    @closeModal={{@closeModal}}
    @tagName="form"
    id="chat-integration-edit-channel-modal"
  >
    <:body>
      <table>
        <tbody>
          <tr class="input">
            <td class="label">
              <label for="provider">
                {{i18n "chat_integration.edit_channel_modal.provider"}}
              </label>
            </td>
            <td>
              {{i18n
                (concat
                  "chat_integration.provider." @model.channel.provider ".title"
                )
              }}
            </td>
          </tr>
  
          <tr class="chat-instructions">
            <td></td>
            <td></td>
          </tr>
  
          {{#each @model.provider.channel_parameters as |param|}}
            <ChannelParamRow @param={{param}} @channel={{@model.channel}} />
          {{/each}}
        </tbody>
      </table>
    </:body>
  
    <:footer>
      <DButton
        @action={{this.save}}
        @label="chat_integration.edit_channel_modal.save"
        @disabled={{not this.validParams}}
        type="submit"
        id="save-channel"
        class="btn-primary btn-large"
      />
  
      <DButton
        @action={{@closeModal}}
        @label="chat_integration.edit_channel_modal.cancel"
        class="btn-large"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "r/eEGRCv",
    "block": "[[[8,[39,0],[[24,1,\"chat-integration-edit-channel-modal\"],[4,[38,2],[\"submit\",[30,0,[\"save\"]]],null]],[[\"@title\",\"@closeModal\",\"@tagName\"],[[28,[37,1],[\"chat_integration.edit_channel_modal.title\"],null],[30,1],\"form\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n          \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,\"for\",\"provider\"],[12],[1,\"\\n              \"],[1,[28,[35,1],[\"chat_integration.edit_channel_modal.provider\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[[28,[37,3],[\"chat_integration.provider.\",[30,2,[\"channel\",\"provider\"]],\".title\"],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[14,0,\"chat-instructions\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[13],[1,\"\\n          \"],[10,\"td\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2,[\"provider\",\"channel_parameters\"]]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@param\",\"@channel\"],[[30,3],[30,2,[\"channel\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,1,\"save-channel\"],[24,0,\"btn-primary btn-large\"],[24,4,\"submit\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"save\"]],\"chat_integration.edit_channel_modal.save\",[28,[37,8],[[30,0,[\"validParams\"]]],null]]],null],[1,\"\\n\\n    \"],[8,[39,7],[[24,0,\"btn-large\"]],[[\"@action\",\"@label\"],[[30,1],\"chat_integration.edit_channel_modal.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\",\"param\"],false,[\"d-modal\",\"i18n\",\"on\",\"concat\",\"each\",\"-track-array\",\"channel-param-row\",\"d-button\",\"not\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/modal/edit-channel.hbs",
    "isStrictMode": false
  });
  class EditChannel extends _component2.default {
    get validParams() {
      return this.args.model.provider.channel_parameters.every(param => {
        const value = this.args.model.channel.get(`data.${param.key}`);
        if (!value?.trim()) {
          return false;
        }
        if (!param.regex) {
          return true;
        }
        return new RegExp(param.regex).test(value);
      });
    }
    async save() {
      try {
        await this.args.model.channel.save();
        this.args.closeModal();
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = EditChannel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditChannel);
});